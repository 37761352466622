<template>
  <v-slide-x-reverse-transition hide-on-leave>
    <v-row
      v-show="showAlert"
      align-content="center"
    >
      <v-card-text class="text-center">
        <v-icon
          :color="type"
          size="220"
        >
          {{ $vuetify.icons.values[type] }}
        </v-icon>
        <h3 :class="textClass">
          {{ text }}
        </h3>
      </v-card-text>
    </v-row>
  </v-slide-x-reverse-transition>
</template>

<script>
export default {
  name: 'FullScreenAlert',
  props: {
    showAlert: { type: Boolean, default: false },
    type: { type: String, default: '' },
    text: { type: String, default: '' }
  },
  data () {
    return {
      textClasses: {
        success: 'success--text text-h4',
        error: 'error--text text-h4',
        warning: 'warning--text text-h4'
      }
    }
  },
  computed: {
    textClass () {
      return this.textClasses[this.type]
    }
  }
}
</script>
