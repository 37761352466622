<template>
  <v-form v-model="formIsValid">
    <v-row>
      <v-col cols="12">
        <custom-text-field
          v-model="client.name"
          autocomplete="off"
          autofocus
          :rules="[rules.required]"
          :label="$t('inputs.workspaceName')"
        />
        <v-card
          class="flex transparent"
          flat
          tile
        >
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              rounded
              :disabled="loading"
              color="primary"
              class="mr-3"
              @click="$emit('update:onboarding-step', 0)"
            >
              {{ $t('general.back') }}
            </v-btn>
            <v-btn
              rounded
              :disabled="loading || !formIsValid"
              :loading="loading"
              color="primary"
              @click="$emit('submit-form')"
            >
              {{ $t('onboarding.createWorkspaceButton') }}
              <v-icon right>
                {{ $vuetify.icons.values.next }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required } from '@/mixins/validation'
import CustomTextField from '@/components/inputs/CustomTextField'

export default {
  name: 'OnboardingWorkspaceForm',
  components: {
    CustomTextField
  },
  mixins: [required],
  props: {
    formData: { type: Object, default: () => { return {} } },
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      formIsValid: false
    }
  },
  computed: {
    client: {
      get () {
        return this.formData
      },
      set (value) {
        this.$emit('update:form-data', value)
      }
    }
  }
}
</script>
