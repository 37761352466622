// RFC 5322
// eslint-disable-next-line no-control-regex
const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i

// E146
const phoneRegex = /^\+?\d{10,14}$/

export const validateEmail = {
  methods: {
    validateEmail (value) {
      if (Array.isArray(value)) {
        return value.every(item => emailRegex.test(item))
      } else {
        return emailRegex.test(value)
      }
    }
  }
}

export const required = {
  data () {
    return {
      rules: {
        required: value => value === 0 || !!value || this.$t('general.rules.required')
      }
    }
  }
}

export const phone = {
  data () {
    return {
      rules: {
        phone: value => phoneRegex.test(value) || this.$t('general.rules.phone')
      }
    }
  }
}

export const minLength = {
  data () {
    return {
      rules: {
        minLength: (value, minLength) => !value ? true : value.length >= minLength ||
          this.$t('general.rules.minLength', { length: minLength })
      }
    }
  }
}

export const numeric = {
  data () {
    return {
      rules: {
        numeric: value => !value ? true : (/^-?[0-9]*\.?[0-9]+$/).test(value) ||
          this.$t('general.rules.number')
      }
    }
  }
}

export const email = {
  data () {
    return {
      rules: {
        email: value => emailRegex.test(value) || this.$t('general.rules.emailFormat')
      }
    }
  }
}

export const fileSize = {
  data () {
    return {
      rules: {
        fileSize: (value, size) => {
          return !value || value.size < size || this.$t('general.rules.fileSizeKB', { size: (size / 1000) })
        }
      }
    }
  }
}

export const createRules = {
  data () {
    return {
      rules: {
        required: value => this.selectedDocumentCategory !== 'proformInvoice' ? value === 0 || !!value || this.$t('general.rules.required') : true,
        requiredArray: value => value.length > 0 || this.$t('general.rules.required'),
        alwaysRequired: value => this.$isNumber(value)
          ? true
          : !!value || this.$t('general.rules.required'),
        emailFormat: value =>
          this.recipients &&
          this.recipients.length > 0
            ? this.validateEmail(value) || this.$t('general.rules.emailFormat')
            : true,
        emailPresence: value => this.isPushEmail
          ? value.length > 0 || this.$t('general.rules.emailPresence')
          : true,
        percent: value => !value ? true : (/(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/i).test(value) ||
          this.$t('general.rules.percent'),
        numeric: value => !value ? true : (/^-?[0-9]*\.?[0-9]+$/).test(value) ||
          this.$t('general.rules.number')
      }
    }
  }
}
