<template>
  <v-container class="fill-height">
    <v-row
      v-if="!onboardingSuccess"
      align="center"
    >
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        cols="4"
      >
        <v-timeline dense>
          <v-timeline-item
            color="success"
            small
            :icon="$vuetify.icons.values.check"
            fill-dot
          >
            <div>
              {{ $t('general.signup') }}
            </div>
          </v-timeline-item>
          <onboarding-timeline-item
            v-for="(step, index) in steps"
            :key="`step-${index}`"
            :index="index"
            :step-props="step.propsByCurrentStep"
            :current-step="onboardingStep"
            :title="step.title"
            :caption="step.caption"
          />
        </v-timeline>
      </v-col>
      <v-col
        class="mx-auto"
        cols="12"
        sm="6"
        offset-md="2"
      >
        <v-slide-x-reverse-transition hide-on-leave>
          <onboarding-client-form
            v-show="onboardingStep === 0"
            :form-data.sync="client"
            :onboarding-step.sync="onboardingStep"
          />
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition hide-on-leave>
          <onboarding-workspace-form
            v-show="onboardingStep === 1"
            :loading="loading"
            :form-data.sync="workspace"
            :onboarding-step.sync="onboardingStep"
            @submit-form="submitForm"
          />
        </v-slide-x-reverse-transition>
      </v-col>
    </v-row>
    <full-screen-alert
      :show-alert="onboardingSuccess"
      type="success"
      :text="$t('onboarding.successMessage')"
    />
  </v-container>
</template>

<script>
import { DateTime } from 'luxon'
import api from '@/api/modules/onboarding'
import { mapGetters } from 'vuex'
import OnboardingClientForm from '@/features/onboarding/components/ClientForm'
import OnboardingWorkspaceForm from '@/features/onboarding/components/WorkspaceForm'
import OnboardingTimelineItem from '@/features/onboarding/components/TimelineItem'
import FullScreenAlert from '@/components/alerts/FullScreenAlert'

export default {
  name: 'Onboarding',
  components: {
    OnboardingClientForm,
    OnboardingWorkspaceForm,
    OnboardingTimelineItem,
    FullScreenAlert
  },
  data () {
    return {
      loading: false,
      onboardingSuccess: false,
      onboardingStep: 0,
      client: {
        name: null,
        contactName: null,
        contactTelephoneNumber: null,
        contactEmail: null
      },
      workspace: {
        name: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'getFormErrorMessages'
    ]),
    steps () {
      return [
        {
          title: this.$t('onboarding.tellUsAboutYou'),
          caption: this.$t('onboarding.tellUsAboutYouHint'),
          propsByCurrentStep: {
            0: {
              icon: this.$vuetify.icons.values.next,
              color: 'primary',
              titleClass: 'title',
              caption: true
            },
            1: {
              icon: this.$vuetify.icons.values.check,
              color: 'success',
              titleClass: '',
              caption: false
            }
          }
        },
        {
          title: this.$t('onboarding.createWorkspace'),
          caption: this.$t('onboarding.createWorkspaceHint'),
          propsByCurrentStep: {
            0: {
              icon: '',
              color: 'grey',
              titleClass: '',
              caption: false
            },
            1: {
              icon: this.$vuetify.icons.values.next,
              color: 'primary',
              titleClass: 'title',
              caption: true
            }
          }
        }
      ]
    }
  },
  methods: {
    async submitForm () {
      this.loading = true
      const { client, workspace } = this
      const timezone = DateTime.local().zoneName
      const response = await api.onboardUser(client, workspace, timezone)
      if (response?.status === 201) {
        this.$store.commit('SET USER ONBOARDING STATUS', await api.getUserOnboardingStatus())
        this.onboardingSuccess = true
        setTimeout(function () {
          this.$router.push({
            name: 'Launcher'
          })
        }.bind(this), 700)
      }
      this.loading = false
    }
  }
}
</script>
