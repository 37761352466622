<template>
  <v-form v-model="formIsValid">
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <custom-text-field
          v-model="client.name"
          autocomplete="organization"
          autofocus
          :label="`${$t('inputs.companyName')} *`"
          counter="128"
          maxlength="128"
          :rules="[rules.required]"
        />
        <custom-text-field
          v-model="client.contactName"
          autocomplete="name"
          :label="`${$t('inputs.contactName')} *`"
          :rules="[rules.required]"
          counter="64"
          maxlength="64"
        />
        <custom-text-field
          v-model="client.contactEmail"
          autocomplete="email"
          :label="`${$t('inputs.contactEmail')} *`"
          :rules="[rules.required, rules.email]"
        />
        <custom-text-field
          v-model="client.contactTelephoneNumber"
          autocomplete="tel"
          :label="$t('inputs.contactTelephoneNumber')"
          :rules="[rules.phone]"
          counter="15"
          maxlength="15"
        />
        <v-card
          class="flex transparent"
          flat
          tile
        >
          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="!formIsValid"
              rounded
              color="primary"
              @click="$emit('update:onboarding-step', 1)"
            >
              {{ $t('general.next') }}
              <v-icon right>
                {{ $vuetify.icons.values.next }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required, email, minLength, phone } from '@/mixins/validation'
import { countriesList } from '@/mixins/create/countries'
import CustomTextField from '@/components/inputs/CustomTextField'

export default {
  name: 'OnboardingClientForm',
  components: {
    CustomTextField
  },
  mixins: [
    required,
    email,
    minLength,
    phone,
    countriesList
  ],
  props: {
    formData: { type: Object, default: () => { return {} } }
  },
  data () {
    return {
      formIsValid: false
    }
  },
  computed: {
    client: {
      get () {
        return this.formData
      },
      set (value) {
        this.$emit('update:form-data', value)
      }
    }
  }
}
</script>
