<template>
  <v-timeline-item
    :color="stepProps[currentStep].color"
    :small="inactiveStep"
    fill-dot
  >
    <template v-slot:icon>
      <v-slide-x-transition hide-on-leave>
        <v-icon
          :key="stepProps[currentStep].icon"
          :small="inactiveStep"
          dark
        >
          {{ stepProps[currentStep].icon }}
        </v-icon>
      </v-slide-x-transition>
    </template>
    <div :class="stepProps[currentStep].titleClass">
      {{ title }}
    </div>
    <v-expand-transition>
      <div
        v-show="stepProps[currentStep].caption"
        class="caption"
      >
        {{ caption }}
      </div>
    </v-expand-transition>
  </v-timeline-item>
</template>

<script>
export default {
  name: 'OnboardingTimelineItem',
  props: {
    index: { type: Number, default: 0 },
    stepProps: { type: Object, default: () => { return {} } },
    currentStep: { type: Number, default: 1 },
    title: { type: String, default: '' },
    caption: { type: String, default: '' }
  },
  computed: {
    inactiveStep () {
      return this.currentStep !== this.index
    }
  }
}
</script>
