<script>
import { VTextField } from 'vuetify/lib'

export default {
  name: 'CustomTextField',
  extends: VTextField,
  props: {
    outlined: { type: Boolean, default: true }
  }
}
</script>
